<template>
    <div id="property">
        <div v-if="loading">
            <Spinner size="large" message="Loading..." line-fg-color="#000" style="padding: 5rem 0;"/>
        </div>
        <div class="content" v-else>
            <div id="slides">
                <vueper-slides 
                    :bullets="false"
                    @slide="activeThumbnail"
                    ref="vueperslides1"
                    id="mainSlides"
                    :dragging-distance="70"
                    :arrows="true"
                    :fixed-height="'100%'">
                    <vueper-slide 
                        v-for="(slide, i) in propertyData.Images" 
                        :key="i" 
                        :image="url + slide.url"
                        class="main_slides"
                        >
                        <template v-slot:loader>
                            <img :alt="`${propertyData.Address} image ${i}`" :src="url+ slide.formats.small.url" class="lazy-load-filter"/>
                        </template>
                    </vueper-slide>
                </vueper-slides>
                <vueper-slides
                    :bullets="false"
                    ref="vueperslides2"
                    :slide-ratio="1 / 8"
                    :dragging-distance="50"
                    @slide="activeSlide"
                    :visible-slides="Math.ceil(window.width/100)"
                    :arrows="false"
                    id="thumbnails"
                    v-if="window.width < 576 && propertyData.Images.length > 4"
                    :fixed-height="window.width > 576 ? '80px' : '50px'">
                    <vueper-slide
                    v-for="(slide, i) in propertyData.Images"
                    :key="i"
                    @click.native="$refs.vueperslides2 && $refs.vueperslides2.goToSlide(i)">
                        <template v-slot:content>
                            <div class="vueperslide__content-wrapper">
                                <img :alt="`${propertyData.address} thumbnail image ${i}`" :src="url+slide.formats.thumbnail.url" />
                            </div>
                        </template>
                    </vueper-slide>
                </vueper-slides>
            </div>
            <div id="propertyDetails">
                <div class="address-container">
                    <h2 :class="propertyData.Special !== 'none' ? 'first' :''">{{ propertyData.Address }}</h2>
                    <h2 v-if="propertyData.Special !== 'none'" class="address-tag">
                        <b>{{propertyData.Special | startCase}}</b>
                    </h2>
                </div>
                <div class="details">
                    <span :class="!propertyData.Bedrooms && !propertyData.Size && !property.NumberOfUnits && !property.BuildingType ? 'end' : ''">{{propertyData.Price | priceFormat}}{{leased}}</span>
                    <span v-if="propertyData.Bedrooms" :class="!propertyData.Bathrooms && !propertyData.Size && !property.NumberOfUnits && !property.BuildingType  ? 'end' : ''">{{propertyData.Bedrooms}} BEDS</span>
                    <span v-if="propertyData.Bathrooms" :class="!propertyData.Size && !property.NumberOfUnits && !property.BuildingType ? 'end' : ''">{{propertyData.Bathrooms}} BATHS</span>
                    <span v-if="propertyData.Size" :class="!property.NumberOfUnits && !property.BuildingType ? 'end' : ''">{{propertyData.Size}} SQFT</span>

                    <span v-if="property.BuildingType" :class="!property.NumberOfUnits ? 'end' : ''">PROPERTY TYPE: {{property.BuildingType}}</span>
                    <span v-if="property.NumberOfUnits" class="end"># UNITS: {{property.NumberOfUnits}}</span>
                </div>
            </div>
            <div id="description" class="full-size">
                <div>
                    <h3>{{propertyData.Address}}, {{propertyData.Region}} {{propertyData.Zip}}</h3>
                    <hr />
                    <p :style="propertyData.Description.length > 1200 || propertyData.VirtualTourURL  ? 'margin-bottom: 2rem;': ''">{{propertyData.Description}}</p>
                    <a v-if="propertyData.VirtualTourURL" target="_blank" :href="propertyData.VirtualTourURL" class="btn_tour">
                        <img src="/images/tour.png" alt="">
                        Virtual Tour
                    </a>
                </div>
            </div>
            <div v-if="propertyData.VimeoId" class='embed-container'>
                <iframe :title="`${propertyData.Address} video`" :src="'https://player.vimeo.com/video/'+ propertyData.VimeoId" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
            </div>
            <div v-if="propertyData.YoutubeId" class='youtube-embed-container'>
                <iframe :title="`${propertyData.Address} video`" :src="'https://www.youtube.com/embed/'+ propertyData.YoutubeId" frameborder='0' allowfullscreen></iframe>
            </div>
        </div>
    </div>
</template>

<script>
import Spinner from 'vue-simple-spinner'
import { VueperSlides, VueperSlide } from 'vueperslides'

export default {
    name: "property",
    components: {
        Spinner,
        VueperSlides, 
        VueperSlide,
    },
    data(){
        return{
            title: "",
            currentIndex: 0,
            url: 'https://api.austinrobbins.com',
            window: {
                width: 0,
                height: 0
            }
        }
    },
    computed:{
        leased: function() {
            if(this.propertyData.Lease){
                return "/mo.";
            }
            return '';
        },
        propertyData: function(){
            return this.$store.getters.properties.find((property) => {
                return property.PropertyData.Slug === this.$route.params.slug;
            }).PropertyData;
        },
        property: function(){
            return this.$store.getters.properties.find((property) => {
                return property.PropertyData.Slug === this.$route.params.slug;
            });
        },
        loading: function(){
            return !this.$store.getters.loaded;
        }
    },
    filters: {
        priceFormat: function (value) {
            if (!value) return ''
            const formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0
            })
            return formatter.format(value)
        }
    },
    methods: {
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;
        },
        activeThumbnail(event){
            this.$refs.vueperslides2.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;
        },
        activeSlide(event){
            this.$refs.vueperslides1.goToSlide(event.currentSlide.index, { emit: false })
            this.currentIndex = event.currentSlide.index;
        },
    },
    metaInfo() {
         let data = this.title; 
         return {
             title: data ? data : "Property | Bob Safranski",
             meta: [{
                name: 'description', 
                content: "Mindful real estate"
            }]
         }
    },
    created() {
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener('resize', this.handleResize)
    },
}
</script>

<style lang="scss" scoped>
#slides{
    height: 100vh;
    margin-top: -59px;
    #mainSlides{
        height: 100%;
    }
}
#propertyDetails{
    background: #000;
    padding: 1rem 0;
    color: white;
    .details, .address-container{
        margin: 2rem 0;
        h2{
            font-weight: 500;
            padding:0rem 2.5rem;
            text-transform: uppercase;
            font-size: 30px;
            &.first{
                border-right: 2px solid white;
            }
        }
        display: flex;
        justify-content: center;
        span {
            font-size: 1.1rem;
            padding:0.75rem 2.5rem;
            border-right: 1px solid white;
            letter-spacing: 2px;
            &.end{
                border: none;
            }
        }
    }
}
#description{
    white-space: pre-line;
    text-align: center;
    max-width: 1000px;
    margin: 0 auto;
    text-align: center;
    hr {
        margin: 2rem auto !important;
    }
    div{
        margin: 5rem 3rem 5rem 3rem;
        h3{
            text-transform: uppercase;
            letter-spacing: 3px;
            font-weight: 500;
            font-size: 1.05rem;
        }
        hr{
            width: 60px;
            text-align: left;
            height: 1px;
            border: 0; 
            border-bottom: 2px solid #000;
            margin: 2rem 0;
        }
        .btn_tour{
            color:#fff;
            background: #000;
            text-decoration: none;
            text-transform: uppercase;
            display: inline-flex;
            align-items: center;
            padding: 0.5rem 1rem;
            letter-spacing: 2px;
            font-weight: 300;
            margin-bottom: 0rem;
            img{
                margin-right: 1rem;
                width: 17px;
                height: auto;
            }
        }
    }
}
.tag{
    width: 220px;
    color:white;
    background: #000;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin: 0 auto;
    top: 1.5rem;
    margin-top: -1.5rem;
    z-index: 15;
    p{
        padding: 0.5rem 0;
        font-size: 1.2rem;
        letter-spacing: 3px;
    }
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}
		
.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.youtube-embed-container{
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}
.youtube-embed-container iframe,
.youtube-embed-container object,
.youtube-embed-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: $tablet) {
    #propertyDetails{
        padding-top: 60px;
        .details, .address-container{
            display: block;
            text-align: center;
            span, h2{
                display: block;
                border: none;
            }
        }
    }
    #description{
        padding-bottom: 3rem;
        div{
            margin-bottom: 0.75rem;
        }
    }

    #propertyDescription{
        padding-bottom: 1.5rem;
    }
    
}
</style>

<style>
#thumbnails{
    padding: 1rem 0;
}
#thumbnails .vueperslide{
    padding: 3px 0;
}
#thumbnails .vueperslide img{
    width: 80px;
    height: 100%;
}
#thumbnails .vueperslide--active img{
    border: 3px solid #000;
}

.vueperslides--fixed-height.vueperslides--bullets-outside{
    margin-bottom: 2em;
}
.vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:after, .vueperslides:not(.no-shadow):not(.vueperslides--3d) .vueperslides__parallax-wrapper:before{
    box-shadow: none;
}
@media (max-width: 576px) { 
    #thumbnails .vueperslide img{
        width: 50px;
    }
}
.lazy-load-filter{
    filter: blur(8px);
    height: auto;
    width: 100%;
}
</style>
